<template>
  <div class="InfluencerDetail">
    <v-dialog v-model="openInfluencerDetail" content-class="white-two" max-width="690px" @keyup.esc="closeDialog()">
      <v-card class="pa-4">
        <v-card-title class="pa-2">
          <div>
            <v-icon color="default" @click="closeDialog()">close</v-icon>
            <br />
          </div>
        </v-card-title>
        <v-card-text class="pt-0 px-4">
          <v-row class="pt-6 pb-10">
            <v-col cols="3" class="mr-1">
              <v-avatar :size="$vuetify.breakpoint.mdAndUp ? '128' : '64'">
                <img v-if="openInfluencer.photo" :src="openInfluencer.photo" />
                <img v-else src="@/assets/img/matching-placeholder.jpg" />
              </v-avatar>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '8'" class="align-self-center">
              <v-row>
                <v-col cols="1" :class="$vuetify.breakpoint.mdAndUp ? '' : 'mr-2'">
                  <naverIcon />
                  <!--                    <instaIcon v-if="type === 'insta'"/>-->
                  <!--                    <youtubeIcon v-if="type === 'youtube'"/>-->
                </v-col>
                <v-col class="subheading">
                  <a
                    :href="'https://blog.naver.com/' + openInfluencer.account"
                    target="_blank"
                    style="color: #000000"
                    >{{ openInfluencer.account }}</a
                  >
                  <span v-if="openInfluencer.innaver" style="color: #15c072; font-size: 13px">
                    <v-icon small color="#15C072" class="ml-1 mb-1">mdi-at</v-icon>
                    네이버 인플루언서
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-chip
                  x-small
                  outlined
                  color="#34CD89"
                  class="ma-0 mr-1 mt-2"
                  v-for="(category, idx) in _.take(_.sortBy(_.toPairs(openInfluencer.mainCategories), 1).reverse(), 2)"
                  :key="idx"
                  >{{ category[0] + ' ' + Math.round(category[1]) + '%' }}
                </v-chip>
              </v-row>
              <v-row>
                <v-list two-line class="pa-0">
                  <v-list-item class="pa-0">
                    <v-list-item-content class="pt-1">
                      <v-list-item-title style="font-size: 10px; color: #808080">일 방문자</v-list-item-title>
                      <v-list-item-subtitle class="caption"
                        >{{ openInfluencer.visitor | numFormat }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content class="pt-1">
                      <v-list-item-title style="font-size: 10px; color: #808080">블로그 순위</v-list-item-title>
                      <v-list-item-subtitle v-if="openInfluencer.rank < 100000000" class="caption">{{
                        openInfluencer.rank | numFormat
                      }}</v-list-item-subtitle>
                      <v-list-item-subtitle v-else class="caption">-</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content class="pt-1">
                      <v-list-item-title style="font-size: 10px; color: #808080">수락률/평점</v-list-item-title>
                      <v-list-item-subtitle class="caption"
                        >{{ (openInfluencer.acceptRate || '-') + '%' }}/<span style="color: #f0cb30">{{
                          '★' + (openInfluencer.rating || '-')
                        }}</span></v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-row>
              <v-row v-if="openInfluencer.innaverData">
                <v-list two-line class="pa-0">
                  <v-list-item class="pa-0">
                    <v-list-item-content class="pt-1">
                      <v-list-item-title style="font-size: 10px; color: #808080">카테고리</v-list-item-title>
                      <v-list-item-subtitle class="caption">{{
                        openInfluencer.innaverData.subCategory
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content class="pt-1">
                      <v-list-item-title style="font-size: 10px; color: #808080">서브 카테고리</v-list-item-title>
                      <v-list-item-subtitle class="caption">{{ openInfluencer.innaverData.cat1 }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-row>
            </v-col>
            <v-spacer></v-spacer>
            <v-col v-if="setFavorite">
              <v-row class="pl-3">
                <v-icon :color="checkFavorite(openInfluencer) ? 'pink' : ''" @click="setFavorite(openInfluencer)">{{
                  checkFavorite(openInfluencer) ? 'mdi-star' : 'mdi-star-outline'
                }}</v-icon>
              </v-row>
              <v-row> 즐겨찾기 </v-row>
            </v-col>
          </v-row>
          <v-row class="pb-8">
            <v-col
              :cols="$vuetify.breakpoint.mdAndUp ? '4' : '6'"
              :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
              <div class="influencer-detail__title">블로그 순위</div>
              <div class="influencer-detail__subtitle secondary--text">{{ openInfluencer.rank | numFormat }}</div>
            </v-col>
            <v-col
              :cols="$vuetify.breakpoint.mdAndUp ? '4' : '6'"
              :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
              <div class="influencer-detail__title">상위 백분율</div>
              <div class="influencer-detail__subtitle secondary--text">{{ openInfluencer.percentage }}%</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row class="influencer-detail__title">대표 샘플</v-row>
              <v-row>
                <v-sheet
                  v-dragscroll
                  style="height: 216px; display: flex; overflow: scroll; overflow-x: auto; white-space: nowrap">
                  <a
                    :href="openInfluencer.reference && openInfluencer.reference[index] && openInfluencer.reference[index].link ? openInfluencer.reference[index].link.replace('m.', '') : '#'"
                    target="_blank"
                    v-for="(metadata, index) in openInfluencer.reference || []"
                    :key="index">
                    <!--                    v-for="(metadata, index) in openInfluencer.metadata" :key="index">-->
                    <div class="mx-1" style="maxwidth: 120px">
                      <img
                        v-if="(metadata || {}).image"
                        width="120px"
                        height="108px"
                        :src="metadata.image || openInfluencer.metadata[index].image"
                        :alt="(metadata || {}).title"
                        @error="e => openInfluencer.reference.splice(index, 1)" />
                      <img v-else src="@/assets/img/matching-placeholder.jpg" />
                      <div>
                        <v-chip v-if="(metadata || {}).category" outlined x-small color="#34CD89" class="ma-0 mr-1">
                          {{ metadata.category }}
                        </v-chip>
                      </div>
                      <p
                        v-if="(metadata || {}).title"
                        v-html="(metadata || {}).title"
                        class="caption"
                        style="width: 120px; color: #484848; white-space: normal"></p>
                    </div>
                  </a>
                </v-sheet>
              </v-row>
              <v-row v-if="(openInfluencer.reviews || []).length > 0" class="influencer-detail__title"
                >슈퍼차트 캠페인 진행 사례</v-row
              >
              <v-row v-if="(openInfluencer.reviews || []).length > 0">
                <v-sheet
                  v-dragscroll
                  style="height: 216px; display: flex; overflow: scroll; overflow-x: auto; white-space: nowrap">
                  <a
                    :href="openInfluencer.reviews && openInfluencer.reviews[index] && openInfluencer.reviews[index].url ? openInfluencer.reviews[index].url.replace('m.', '') : '#'"
                    target="_blank"
                    v-for="(review, index) in openInfluencer.reviews || []"
                    :key="index">
                    <!--                    v-for="(metadata, index) in openInfluencer.metadata" :key="index">-->
                    <div class="mx-1" style="maxwidth: 120px">
                      <img
                        v-if="(review || {}).image"
                        width="120px"
                        height="108px"
                        :src="review.image || openInfluencer.reviews[index].image"
                        :alt="(review || {}).title"
                        @error="e => openInfluencer.reviews.splice(index, 1)" />
                      <img v-else src="@/assets/img/matching-placeholder.jpg" />
                      <p
                        v-if="(review || {}).title"
                        v-html="(review || {}).title"
                        class="caption"
                        style="width: 120px; color: #484848; white-space: normal"></p>
                    </div>
                  </a>
                </v-sheet>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="pb-8">
            <v-col>
              <v-row class="influencer-detail__title">주간 블로그 순위</v-row>
              <v-row>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
                  <div v-if="openInfluencer.rank < 100000000" class="influencer-detail__subtitle primary--text">
                    {{ openInfluencer.rank | numFormat }}
                  </div>
                  <div v-else class="influencer-detail__subtitle primary--text">-</div>
                  <div class="text-center caption pt-3" style="color: #808080">매주 월요일 기준</div>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '8' : '12'">
                  <naverChart :width="600" :height="200" :data="historyChartData"></naverChart>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-row>
              <v-col :cols="$vuetify.breakpoint.mdAndUp ? '12' : '12'" class="pb-8">
                <v-row class="influencer-detail__title">분야별 상위노출 비율</v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '3' : '4'">
                    <donutChart
                      :width="144"
                      :height="144"
                      :data="categoryChartData"
                      :labels="categoryChartLabels"></donutChart>
                  </v-col>
                  <v-spacer :style="$vuetify.breakpoint.mdAndUp ? 'display: none;' : 'display: flex'"></v-spacer>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'" style="padding-left: 48px">
                    <v-row
                      v-for="(category, idx) in _.union(
                        _.take(_.sortBy(_.toPairs(openInfluencer.categories), 1).reverse(), 5),
                        [categoryEtc]
                      )"
                      :key="idx">
                      <v-col class="pa-2" cols="2">
                        <div
                          style="width: 12px; height: 12px"
                          :style="
                            'background: ' + ['#DD2333', '#E34956', '#EB717B', '#F29BA2', '#FAC8CC', '#ECEFF1'][idx]
                          "></div>
                      </v-col>
                      <v-col cols="5">{{ category[0] }}</v-col>
                      <v-col cols="5">{{ category[1] | numFormat }}%</v-col>
                    </v-row>
                  </v-col>
                  <v-spacer :style="$vuetify.breakpoint.mdAndUp ? 'display: flex;' : 'display: none'"></v-spacer>
                </v-row>
              </v-col>
              <v-col class="pb-6">
                <v-row class="influencer-detail__title">상위노출 키워드</v-row>
                <!--                <v-chip small default outlined color="#BD1328" class="ma-0 mr-1 mb-2"-->
                <!--                        v-for="(keyword, idx) in _.take(keywords, 20)" :key="idx">{{ keyword.name }}-->
                <!--                </v-chip>-->
                <v-row>
                  <v-list style="width: 100%">
                    <v-list-item>
                      <v-row>
                        <v-col cols="6">
                          <v-list-item-title style="font-size: 13px; color: #757575">노출중인 키워드</v-list-item-title>
                        </v-col>
                        <v-col cols="3">
                          <v-list-item-title
                            :class="orderBy === 'mmqccnt' ? 'font-weight-bold' : 'font-weight-normal'"
                            style="font-size: 13px; color: #757575"
                            @click="changeOrder('mmqccnt')"
                            >키워드 검색량<v-icon v-if="orderBy === 'mmqccnt'">{{
                              order === 'desc' ? 'mdi-chevron-down' : 'mdi-chevron-up'
                            }}</v-icon></v-list-item-title
                          >
                        </v-col>
                        <v-col cols="3">
                          <v-list-item-title
                            :class="orderBy === 'rank' ? 'font-weight-bold' : 'font-weight-normal'"
                            style="font-size: 13px; color: #757575"
                            @click="changeOrder('rank')"
                            >노출 순위<v-icon v-if="orderBy === 'rank'">{{
                              order === 'desc' ? 'mdi-chevron-down' : 'mdi-chevron-up'
                            }}</v-icon></v-list-item-title
                          >
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-list-item v-for="(keyword, index) in keywordsAll ? keywords : _.take(keywords, 5)" :key="index">
                      <v-col cols="6">
                        <v-list-item-content style="font-size: 14px; color: #484848">
                          {{ keyword.name }}
                        </v-list-item-content>
                      </v-col>
                      <v-col cols="3">
                        <v-list-item-content style="font-size: 14px; color: #484848">
                          {{ keyword.mmqccnt | numFormat }}
                        </v-list-item-content>
                      </v-col>
                      <v-col cols="3">
                        <v-list-item-content style="font-size: 14px; color: #484848">
                          {{ keyword.ids ? JSON.parse(keyword.ids).indexOf(openInfluencer.account + '') + 1 : 0 }}위
                        </v-list-item-content>
                      </v-col>
                    </v-list-item>
                  </v-list>
                </v-row>
                <v-row class="text-center">
                  <v-col @click="keywordsAll = true"> + 모두 보기 </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-row>
          <v-row v-if="checkProposition">
            <v-col class="pb-4">
              <v-row class="influencer-detail__title">예상 광고 단가 추이</v-row>
              <v-row>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
                  <div class="influencer-detail__subtitle primary--text">₩ {{ openInfluencer.adFee | numFormat }}</div>
                  <div class="text-center caption pt-3" style="color: #808080">매주 월요일 기준</div>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '8' : '12'">
                  <naverChart :width="600" :height="200" :data="historyAdFeeChartData"></naverChart>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="$route.path !== '/campaign/proposition' && checkProposition">
              <v-row class="influencer-detail__title" style="color: #34cd89; font-weight: 800"
                >이 블로거에게 제안하기</v-row
              >
              <v-row>
                <v-col>
                  <v-row style="color: #484848" class="pb-1">예상 광고 단가</v-row>
                  <v-row style="font-size: 18px; color: #000000" class="pb-4"
                    >₩ {{ openInfluencer.adFee | numFormat }}
                  </v-row>
                </v-col>
                <v-col>
                  <v-row style="color: #484848" class="pb-1">평균 수락 금액</v-row>
                  <v-row style="font-size: 18px; color: #000000" class="pb-4">
                    <v-col v-if="$route.path === '/'">
                      <router-link to="/signin" style="font-size: 18px" class="secondary--text">
                        가입하고 확인하기
                      </router-link>
                    </v-col>
                    <v-col v-else> ₩ {{ (openInfluencer.acceptAvg || 0) | numFormat }} </v-col>
                  </v-row>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
                  <v-row style="color: #484848" class="pb-1">제안 광고비</v-row>
                  <v-row v-if="$route.path === '/'" style="font-size: 18px; color: #4770bd" class="pb-4"
                    >₩ {{ openInfluencer.adFee | numFormat }}</v-row
                  >
                  <v-row
                    v-else
                    style="font-size: 18px; color: #4770bd"
                    class="pb-4"
                    @click="
                      () => {
                        showPointEditDialog = true;
                      }
                    ">
                    <v-icon color="secondary" class="edit-button" small> edit </v-icon>
                    ₩ {{ (openInfluencer.price || openInfluencer.adFee) | numFormat }}
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="$route.path === '/'" class="rouge" flat @click="openInfluencerDetail = false">
            <router-link to="/signin" class="white-two--text"> 가입하고 제안하기 </router-link>
          </v-btn>
          <v-menu
            v-else-if="checkProposition && updateToProposition"
            v-model="openInfluencer.campaignAddMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="rouge"
                class="white-two--text ma-0 ml-4"
                tile
                flat
                v-bind="attrs"
                v-on="on"
                style="font-size: 13px">
                제안 목록에 추가
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-subheader style="color: #484848; font-size: 13px">다음 캠페인에 추가</v-subheader>
                <v-list-item
                  v-for="(campaign, index) in campaigns.filter(campaign => (campaign.channel || 'naver') === 'naver')"
                  @click="updateToProposition(campaign.id, openInfluencer.id)"
                  :key="index">
                  <v-list-item-title
                    style="font-size: 14px"
                    :style="checkProposition(campaign.id, openInfluencer.id) === 2 ? 'color:#c2c2c2' : 'color:#484848'">
                    {{ campaign.name }}
                    <v-chip outlined small color="#757575" v-if="checkProposition(campaign.id, openInfluencer.id) === 2"
                      >제안중
                    </v-chip>
                    <v-chip outlined small color="#BD1328" v-else-if="checkProposition(campaign.id, openInfluencer.id)"
                      >삭제
                    </v-chip>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showPointEditDialog"
      content-class="white-two"
      max-width="214px"
      @keyup.esc="showPointEditDialog = false">
      <v-card class="pa-4">
        <v-card-text class="font-size:13px;color:#484848;">
          제안 금액
          <v-tooltip bottom max-width="300">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small color="#4770BD" class="mb-1" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
            </template>
            예상 광고단가의 80-200% 금액으로 제안하실 수 있습니다.
          </v-tooltip>
          <v-row>
            <v-col cols="9">
              <v-text-field
                v-model.number="openInfluencer.price"
                type="number"
                @input="openInfluencer.price = Math.abs($event)"
                outlined
                dense
                hide-details
                style="font-size: 13px">
              </v-text-field>
            </v-col>
            <v-col class="ml-2 mt-2"> 원 </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile class="rouge white-two--text" @click="showPointEditDialog = false">확인</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import campaignMixin from '@/components/shared/campaignMixin';
import naverChart from '@/components/campaign/detail/report/NaverChart';
import donutChart from '@/components/campaign/detail/report/DonutChart';

export default {
  name: 'Influencerdetail',
  mixins: [campaignMixin],
  props: ['value', 'openInfluencer', 'checkProposition', 'updateToProposition', 'checkFavorite', 'setFavorite'],
  components: {
    naverIcon: () => import('@/assets/img/campaign/naver.svg'),
    // instaIcon: () => import('@/assets/img/campaign/insta.svg'),
    // youtubeIcon: () => import('@/assets/img/campaign/youtube.svg'),
    naverChart,
    donutChart,
  },
  data() {
    return {
      openInfluencerDetail: this.value,
      historyChartData: [],
      categoryChartData: [],
      categoryChartLabels: [],
      historyAdFeeChartData: [],
      categoryEtc: ['기타', 0],
      showPointEditDialog: false,
      keywords: [],
      keywordsAll: false,
      orderBy: 'mmqccnt',
      order: 'desc',
    };
  },
  watch: {
    value(value) {
      if (value === false) {
        this.keywordsAll = false;
      }
      this.openInfluencerDetail = value;
    },
    openInfluencerDetail(value) {
      this.$emit('input', value);
    },
    async openInfluencer(value) {
      this.historyChartData = [];
      this.categoryChartData = [];
      this.historyAdFeeChartData = [];
      let rankHistories = await this.axios.get('/influencer/rank/history?id=' + value.id);
      this.keywords = await this.axios.get('/influencer/keyword?id=' + value.id);
      this.keywords = this.keywords.data.keywords;
      this.keywords = this.keywords.map(keyword => {
        keyword.rank = 0;
        if (keyword.ids) {
          keyword.rank = JSON.parse(keyword.ids).indexOf(this.openInfluencer.account + '') + 1;
          return keyword;
        }
        return keyword;
      });
      this.keywords = this._.orderBy(this.keywords, [this.orderBy], [this.order]);
      rankHistories = this._.take(this._.orderBy(rankHistories.data.histories, ['week'], ['desc']), 12);
      this.historyChartData.push({
        label: '',
        data: rankHistories.map(history => {
          return {
            x: history.week,
            y: history.rank,
          };
        }),
        backgroundColor: 'rgba(189, 19, 40, .7)',
        hoverBackgroundColor: 'rgba(189, 19, 40, .7)',
        borderColor: 'rgba(189, 19, 40, .7)',
        borderWidth: 2,
        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
        pointBorderColor: 'rgba(0, 0, 0, 0)',
        pointBorderWidth: 0,
        fill: false,
        showLine: true,
        reverseY: true,
      });
      let categories = this._.take(this._.sortBy(this._.toPairs(value.categories), 1).reverse(), 5);
      this.categoryChartData.push({
        data: categories.map(category => {
          return category[1];
        }),
        backgroundColor: categories.map((category, idx) => {
          return ['#DD2333', '#E34956', '#EB717B', '#F29BA2', '#FAC8CC', '#ECEFF1'][idx];
        }),
      });
      this.categoryChartLabels = categories.map(category => {
        return category[0].padEnd(10, ' ') + category[1].toFixed(1) + '%';
      });
      this.categoryEtc = [
        '기타',
        100 -
          this._.sum(
            categories.map(category => {
              return Math.round(category[1]);
            })
          ),
      ];
      this.historyAdFeeChartData.push({
        label: '',
        data: this._.compact(
          rankHistories.map(history => {
            if (history.adFee) {
              return {
                x: history.week,
                y: history.adFee,
              };
            } else return null;
          })
        ),
        backgroundColor: 'rgba(189, 19, 40, .7)',
        hoverBackgroundColor: 'rgba(189, 19, 40, .7)',
        borderColor: 'rgba(189, 19, 40, .7)',
        borderWidth: 2,
        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
        pointBorderColor: 'rgba(0, 0, 0, 0)',
        pointBorderWidth: 0,
        fill: false,
        showLine: true,
      });
    },
  },
  methods: {
    closeDialog() {
      this.keywordsAll = false;
      this.openInfluencerDetail = false;
    },
    changeOrder(orderBy) {
      this.orderBy = orderBy;
      this.order = this.orderBy == orderBy && this.order == 'desc' ? 'asc' : 'desc';
      this.keywords = this._.orderBy(this.keywords, [this.orderBy], [this.order]);
    },
  },
};
</script>

<style scoped lang="scss">
.influencer-detail__title {
  font-family: 'Spoqa Han Sans Neo';
  font-size: 14px;
  padding-bottom: 4px;
  border-bottom: solid 1px #f5f5f5;
  margin-bottom: 16px;
}

.influencer-detail__subtitle {
  font-family: 'Spoqa Han Sans Neo';
  font-size: 27px;
  text-align: center;
  font-weight: 300;
  padding-top: 8px;
}
</style>
